import React from 'react';
import { bigRangeMeasure, smallRangeMeasure } from './constants';
import { getPercentage, getPriceRepresntValue, getClosestThousandValue } from './helpers';

interface IPriceRangeSlider {
  minValue: number;
  maxValue: number;
}

const PriceRange = ({ minValue, maxValue }: IPriceRangeSlider) => {
  const range = maxValue - minValue;
  const isBigRange = range > 500;
  const min = isBigRange ? minValue - bigRangeMeasure : minValue - smallRangeMeasure;
  const max = isBigRange ? maxValue + bigRangeMeasure : maxValue + smallRangeMeasure;

  const getRangeStyle = () => ({
    left: `${getPercentage(minValue, min, max)}%`,
    width: `${getPercentage(maxValue, min, max) - getPercentage(minValue, min, max)}%`
  });

  return (
    <div className="relative w-10/11 mt-32 ml-2">
      <h3 className="text-xl font-semibold mb-2 text-center">Price Range</h3>
      <div className="relative h-8 bg-gray-300 rounded-full mt-8" style={{ width: '100%' }}>
        <div className="absolute top-0 h-full bg-blue-500" style={getRangeStyle()} />
        <div
          className="absolute w-8 h-8 bg-blue-500 rounded-full"
          style={{ left: `${getPercentage(minValue, min, max)}%`, top: '50%', transform: 'translate(-50%, -50%)' }}
        />
        <div
          className="absolute w-8 h-8 bg-blue-500 rounded-full"
          style={{ left: `${getPercentage(maxValue, min, max)}%`, top: '50%', transform: 'translate(-50%, -50%)' }}
        />
      </div>
      <span className="absolute text-sm" style={{ left: 0, top: '100%' }}>
        {getPriceRepresntValue(getClosestThousandValue(min.toFixed(0)))}
      </span>
      <span className="absolute text-sm" style={{ right: 0, top: '100%' }}>
        {getPriceRepresntValue(getClosestThousandValue(max.toFixed(0)))}
      </span>
      <span className="absolute text-sm" style={{ left: `${getPercentage(minValue, min, max) - 5}%`, top: isBigRange ? '40%' : '100%' }}>
        {getPriceRepresntValue(minValue.toFixed(0))}
      </span>
      <span className="absolute text-sm" style={{ left: `${getPercentage(maxValue, min, max)}%`, top: isBigRange ? '40%' : '100%' }}>
        {getPriceRepresntValue(maxValue.toFixed(0))}
      </span>
    </div>
  );
};

export default PriceRange;
